import React from "react";
import meter1 from "../../assets/meter1.svg";
import meter2 from "../../assets/meter2.svg";
import meter3 from "../../assets/meter3.svg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import colorSharp from "../../assets/color-sharp.png";

function Skills() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>Skills</h2>
              <p>
              Contamos con un equipo especializado para resolver tus dudas, y realizar los diseños basado a lo que el cliente desea.
              <br></br>
              Entregamos sprints cada semana para ir mostrando el avance, nuestro tiempo de entrega es de 2 semanas aproximadamente.
              </p>
              <Carousel
                responsive={responsive}
                infinite={true}
                className="owl-carousel owl-theme skill-slider"
              >
                <div className="item">
                  <img src={meter1} alt="Image" />
                </div>
                <div className="item">
                  <img src={meter2} alt="Image" />
                  <h5>Market Place</h5>
                </div>
                <div className="item">
                  <img src={meter3} alt="Image" />
                  <h5>E-commerce</h5>
                </div>
                <div className="item">
                  <img src={meter1} alt="Image" />
                  <h5>Front, Back, Base de datos</h5>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  );
}

export default Skills;
